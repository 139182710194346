<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalForm"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            <template v-if="isEditNote===true">
              {{ $t('Editar nota') }}
            </template>
            <template v-else>
              {{ $t('Nova nota') }}
            </template>
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <validation-observer
          #default="{ }"
          ref="formSaveValidatorSW044"
        >
          <b-form
            @submit.prevent
          >
            <vue-perfect-scrollbar
              class="b-sidebar-scrollarea-form"
              :settings="configSettingsScroll"
            >
              <b-container
                class="pt-2 pb-0 pl-2 pr-2"
                fluid
              >
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Comentário')"
                      >
                        <b-form-input
                          v-model="comentario"
                          placeholder=""
                          autocomplete="off"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-container>
            </vue-perfect-scrollbar>
            <div
              class="d-flex p-1 justify-content-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                @click.stop.prevent="saveFormSW044"
              >
                {{ $t('Guardar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-1"
                @click.stop.prevent="clearFormSW044"
              >
                {{ $t('Limpar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click.stop.prevent="hide"
              >
                {{ $t('Cancelar') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BFormGroup, BButton, BRow, BCol, BForm, BContainer, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { onUnmounted } from '@vue/composition-api'
import modelSW044 from '@store-modules/crm/leads/sw044'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import btoa from 'btoa'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    VuePerfectScrollbar,
    BContainer,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    closeModal: {
      type: Function,
      require: true,
      default: null,
    },
    keyRegLeadImport: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    closeModalForm() {
      if (typeof this.$parent.closeFormSW044 !== 'undefined') {
        this.$parent.closeFormSW044()
      }

      if (typeof this.closeModal !== 'undefined' && this.closeModal !== null) {
        this.closeModal()
      }
    },
    async saveFormSW044() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorSW044.validate()
          .then(async valid => {
            if (valid) {
              self.$root.$emit('app::loading', true)
              this.$store.dispatch('sw044/saveSW044', { sw044s03: this.comentario, sw044s01: this.idNote, sw044s11: this.keyRegLeadImport }).then(res => {
                self.$root.$emit('app::loading', false)
                self.showMsgSuccessRequest(res)
                self.closeModalForm()
                if (typeof self.$parent.init !== 'undefined') {
                  self.$parent.init()
                }
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Tem de preencher o comentário')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Tem de preencher o comentário')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async clearFormSW044() {
      try {
        this.comentario = null
        this.$refs.formSaveValidatorSW044.reset()
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao limpar formulário') })
      }
    },
  },
  setup(props) {
    const MODEL_SW044 = 'sw044'
    let comentario = null
    let isEditNote = false
    let idNote = null

    if ('sw044s01' in props.data) {
      isEditNote = true
      idNote = btoa(props.data.sw044s01)
    }

    if ('sw044s03' in props.data) {
      comentario = props.data.sw044s03
    }

    if (!store.hasModule(MODEL_SW044)) {
      store.registerModule(MODEL_SW044, modelSW044)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW044)) store.unregisterModule(MODEL_SW044)
      })
    }

    return {
      isEditNote,
      comentario,
      idNote,

      configSettingsScroll,
    }
  },
}
</script>
